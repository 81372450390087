export default (clinic: string|undefined) => {
  if (clinic === undefined) {
    return;
  }

  // из-за "Беляево " приходится удалять пробелы
  clinic = clinic.replace(/\s/g, '');

  const vowels = ['а', 'у', 'о', 'и', 'э', 'ы', 'я', 'ю', 'е', 'ё'];

  const lastLetter = clinic.slice(-1);
  
  const isConsonant = vowels.includes(lastLetter) === false;

  if (isConsonant) {
    return 'на ' + clinic + 'е';
  }
  if (lastLetter === 'о') {
    return 'в ' + clinic;
  }
  if (lastLetter === 'ы' || lastLetter === 'и') {
    return 'в ' + clinic.slice(0, -1) + 'ах';
  }
  if (lastLetter === 'я') {
    return 'на ' + clinic.slice(0, -2) + 'ой';
  }
};
